@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --light-background: #fdf5e7;
  --light-secondary-background: #f9e8ca;
  --dark-background: #141414;
  --dark-secondary-background: black;
  --light-text: #000;
  --dark-text: #fcedd3;
  --light-grey-text: #808080;
  --dark-grey-text: #a4a4a4 
}

.text-gradient {
  background: linear-gradient(to right, #1c7efd, #ff8a0c, #863aff);
  /* background: linear-gradient(to right, #ff8a0c, #863aff); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Optional: CSS for broader browser compatibility */
  background-clip: text;
  color: transparent;
  width: fit-content;
  background-size: 300%;
  animation: bgGradient 3s infinite alternate;
}

.shine-gradient{
    background: linear-gradient(to right, #3c3c3c, #808080, #fff );
    -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      /* Optional: CSS for broader browser compatibility */
      background-clip: text;
      color: transparent;
      width: fit-content;
      background-size: 300%;
      animation: bgGradient 3s infinite alternate;
}


.bg-gradient, .view-project-btn:hover {
  background: linear-gradient(to right, #1cb6fd, #ff8a0c, #863aff);
  background-size: 300%;
  animation: bgGradient 3s infinite alternate;
}

@keyframes bgGradient {
  0%{
    background-position: left;
  }
   100%{
    background-position: right;
   }
}

.bg-gradient-1 {
  background: linear-gradient(to right, #1cb6fd, #863aff, #ff3a44);
  background-size: 300%;
  animation: bgGradient 1s infinite alternate;
}

.bg-gradient-2 {
  background: linear-gradient(to right, #ff3af2, #ff8a0c, #ffa20c);
  background-size: 300%;
  animation: bgGradient 1s infinite alternate;
}

.bg-gradient-3 {
  background: linear-gradient(to right, #ff8a0c, #ff993a, #1cb6fd);
  background-size: 300%;
  animation: bgGradient 1s infinite alternate;
}

.view-project-btn{

}





body{
  background: #fdf5e7;
  /* background: #141414; */
 
  /* font-family: 'DrukWideMediumRegular', sans-serif; */
  font-family: 'Manrope', sans-serif;
  font-family: "Francois One", sans-serif;
  font-family: "Geologica", sans-serif;
  /* overflow-x: hidden; */
  text-transform: uppercase; 
 
}

.section-gradient {
  background: linear-gradient(to bottom, var(--dark-background), black);
}

.section-gradient-reverse {
  background: linear-gradient(to bottom, black, var(--dark-background));
}

.border-gradient {
  border: 3px solid transparent;
  /* Define border width and transparent color */
  border-image: linear-gradient(to right, #ff8a0c, #863aff);
  /* Define gradient */
  border-image-slice: 1;
  /* Ensure the gradient is not sliced */
}

button{
  font-family: 'Geologica', sans-serif;
  text-transform: uppercase;
  padding: none;
}

.button-primary, .button-secondary{
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer !important;
  transition: all 0.3s ease-in-out;
  /* color: white; */
}

.button-primary {
  background: #333;
  border: 2px solid #333;
}

.button-secondary {
  background: transparent;
  border: 2px solid var(--light-background);
}

.button-primary:hover, .button-secondary:hover {
  background: var(--light-background);
  border: 2px solid var(--light-background);
  color: black;
}



html{
  color: black;
  /* color: #fcedd3; */
}

body{
  background: var(--dark-background);
  color: var(--dark-text);
  transition: all 0.5s;
}


html.dark body{
  background: var(--dark-background);
  color: var(--dark-text);
}

#navbar{
  transition: all 0.4s;
}

.container{
  max-width: 92%; 
  margin: 0 auto;
}




.heading{
  font-weight: bold;
  font-size: 40px;
  /* text-align: center; */
  margin-bottom: 50px;
}

/* Nav Menu */

.main-list{
  /* margin-bottom: 150px; */
}

.main-list, .main-list li{
  min-width: 700px;
}


.main-list li{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom:20px;
  font-size: 20px;
  color: var(--light-background);
}

.main-list li::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--light-background);;
}

.project-list{
  transition: all 0.3s;
  overflow: hidden;
}

.project-list li{
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.project-list li::after {
  content: none;
  /* Set content to none to remove the pseudo-element */
}

.project-list li{
  margin-left: 20px;
  font-size: 14px;
  padding: none;
  border-bottom: none;
}

.main-list li, .project-list li{
  cursor: pointer;
}

.reverse .project-content{
  order: -1;
}






/* INFINTIE SCROLLER */

.scroller {
  max-width: 100%;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg,
      transparent,
      white 20%,
      white 80%,
      transparent);
  mask: linear-gradient(90deg, transparent, rgb(255, 255, 255) 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

@keyframes animateBg {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.75);
  }

  100%{
    transform: scale(1);
  }


 
}

/* Apply the animation to the #hero-image-bg element */
#hero-image-bg {
  animation: animateBg 30s infinite;
  /* 'forwards' ensures it stays scaled */
  /* Optional: smooth transitions during the animation */
  transition: transform 30s;
}




.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  background: var(--light-secondary-background);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
}



html .tag-list li{
  background: var(--dark-secondary-background);
  color: var(--dark-text);
} 



@media(max-width: 768px){
    .main-list,
    .main-list li {
      min-width: 100%;
    }

    .reverse .project-content {
      order: 3;
    }
}

@media(max-width: 500px) {
  .container{
    max-width: 90%;
  } 
  
  .heading {
      font-size: 26px;
      margin-bottom: 25px;
  }


  .tag-list li {
    padding: 0.8rem;
    font-size: 0.75rem;
  }
}